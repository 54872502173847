import React from "react";
import Company from './pages/company';
import VideoBack from './components/videoback'
import Canine from './pages/knineadapted';

import Header from './components/Header';
import Subscribe from './components/Subscribe';
import Social from './components/social/social';
import Form from './pages/form';
import K9special from './pages/caninespecialized' 

function App() {
  
  

  return (
    
      <div className="container">
        
        <Social />
        <Header />
        <VideoBack />
        <Company />
        <Canine />
        <K9special />
        <Form /> 
        <Subscribe />   
      </div>
      );
 }
    
      
      export default App;
