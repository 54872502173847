import React from 'react'
import Videobox from '../components/videobox'
import {FormattedMessage} from 'react-intl';
import '../index.css'
function Knineadapted() {
    return (
        <div id='canineadapted'>
            <div className='b-container'>
            <Videobox title={<FormattedMessage id='k9adapted' defaultMessage='Any Environment'/>}url='https://youtu.be/AHmtWL-lLlg' features={<FormattedMessage id='ck9adapted' defaultMessage='Our canines are training to do the job in any environment'/>}/>
            <Videobox title={<FormattedMessage id='k9adapted1' defaultMessage='Any circunstance'/>}url='https://youtu.be/nAN2bbTxdkw' features={<FormattedMessage id='ck9adapted1' defaultMessage='Our canines are training to do the job in any circunstance'/>}/>
            <Videobox title={<FormattedMessage id='k9adapted2' defaultMessage="Any Detection Area"/>} url='https://youtu.be/NAliQVBKZkw' features={<FormattedMessage id='ck9adapted2' defaultMessage='They have the autonomy to seek and smell the objetive to detect'/>}/>
            </div>
            
        </div>
    )
}

export default Knineadapted
