import React from 'react'
import Videobox from '../components/videobox';
import {FormattedMessage} from 'react-intl'
function Specialized() {
    return (
        <div id='specialized'>
            <div className='b-container'>
            <Videobox title={<FormattedMessage id='k9specialized' defaultMessage='Canine for defense'/>}url='https://youtu.be/C7G1gOisBXA' features={<FormattedMessage id='ck9specialized' defaultMessage='Companies require trained defense canines to take the right action in many situations' />}/>
            <Videobox title={<FormattedMessage id="k9specialized1" defaultMessage='Canines for detect'/>} url='https://youtu.be/6EPxSy-8Igs' features={<FormattedMessage id='ck9specialized1' defaultMessage='Companies in the logistics area, docks, surveillance service providers and many others have found in our service an integral solution'/>}/>
            <Videobox title={<FormattedMessage id="k9specialized2" defaultMessage='Many kinds of Industries'/>}url='https://youtu.be/OA5bICVtJ00' features={<FormattedMessage id='ck9specialized2' defaultMessage='Companies in the logistics area, docks, surveillance service providers and many others have found in our service an integral solution'/>}/>
            </div>
        </div>
    )
}

export default Specialized