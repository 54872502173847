import React, {useContext} from 'react'
import {Link} from 'react-scroll'
import logo from '../images/ccb.png'
import '@fortawesome/fontawesome-free'
import spain from '../images/spain.png'
import english from '../images/united-kingdom.png'
import {FormattedMessage} from 'react-intl';
import langContext from './../context/langContext';

function Navbar() { 
    const idioma= useContext(langContext);
    
    return (
        <div>
            <nav>
                <Link to='main' className="logo" smooth={true} duration={2000}>
                    <img src={logo} alt="logo" />
                </Link>
                <input className='menu-btn' type="checkbox" id='menu-btn' />
                <label className='menu-icon' for='menu-btn'>
                    <span className='nav-icon'></span> 
                </label>
                <ul className="menu">
                    <li><Link to='main' className='active' smooth={true} duration={1000}><FormattedMessage id='menu.home' defaultMessages='Home' /></Link></li>
                    <li><Link to='company' smooth={true} duration={1000}><FormattedMessage id='menu.company' defaultMessage='Company'/></Link></li>
                    
                    <li><Link to='canineadapted' smooth={true} duration={1000}><FormattedMessage id='menu.k9adapted' defaultMessage='K9 Adapted'/></Link></li>
                    <li><Link to='specialized' smooth={true} duration={1000} ><FormattedMessage id='menu.k9specialized' defaultMessage='K9 Especialized'/></Link></li>
                    <li><Link to='content' smooth={true} duration={1000}><FormattedMessage id='menu.contactus' defaultMessage='Contant Us'/></Link></li>
                    <li><Link to='subscribe'smooth={true} duration={1000} ><FormattedMessage id='menu.subscribe' defaultMessage='Subscribe'/></Link></li>
                   
                </ul>
                <ul className="menu" >
                 <button id='btnLang'onClick={()=>idioma.establecerLenguaje('es-CO')}><img src={spain} alt="Español" width="25" height="25"/></button>
                 <button id='btnLang' onClick={()=>idioma.establecerLenguaje('en-US')}><img src={english} alt="English" width="25" height="25"/> </button>     
                </ul>    
                   
            </nav>
            
        </div>
    )
}

export default Navbar
