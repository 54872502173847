import React from "react";
import Videoback from "../video/video3.mp4";


const videoBack = ()=>{
    return(
        <div className="App">
            <video 
        autoPlay    
        loop
        muted 
        style={{
            //position:"absolute",
            width:"100%",
            //left:"50%",
            //top:"50%",
            //height:"100%",
            //transform:"translate(-50%, -50%)",
            zIndex:"-1"
        }}
        >
            <source src={Videoback} type="video/mp4"/>
        </video>
        </div>
        );
};
export default videoBack