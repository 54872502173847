import emailjs from 'emailjs-com';
import React from 'react';
import '@fortawesome/fontawesome-free';
import '../components/contact.css';
import 'reactstrap'

function Form() {
    const valores=document.querySelector('#form');
    console.log(valores)
    function sendEmail(e) {


        e.preventDefault();
        
        emailjs.sendForm('gmailMessage', 'template_JwcSbaDs', e.target, 'user_iwRWBmjgPkz6ldFsUKIDQ')
            .then((result) => {
                alert('Message sending succesfully');
            }, (error) => {
                alert(error.message);
            });
        e.target.reset();
    }
    return (
        <div className='content'>
            <h3 className='logo'>Contact <span>Us</span></h3>

            <div className="contact-wrapper animated bounceInUp">
                <div className='contact-form'>
                    
                    <form id='form' onSubmit={sendEmail}>
                        <p>
                            <label for='Fullname' >Fullname</label>
                            <input type="text" name="fullname" id='fullname' required />
                        </p>
                        <p>
                            <label for='Email'>Email</label>
                            <input type="email" name="email" required/>
                        </p>
                        <p>
                            <label >Phone</label>
                            <input type="tel" name="phone" required/>
                        </p>
                        <p>
                            <label >Select a subject</label><br/>
                            <select name="affair" required>
                            <option >Select an Option please</option>
                                <option >i need a more information</option>
                                <option >i need a adapted canine for training</option>
                                <option >i need a trained canine for detection</option>
                            </select>
                            
                        </p>
                        <p className='block'>
                            <label >Message</label>
                            <textarea name="message" rows="3" required></textarea>
                        </p>
                        <p className='block'>
                            <button type="submit" className='btn btn-info' value='Send'>Send</button>
                        </p><br /><br />
                    </form>


                </div>
                <div className='contact-info'>
                    <h4>More Info</h4>
                    <ul>
                        <li><i class="fas fa-map-marker-alt"></i>Arjona Bolivar Colombia</li>
                        <li><i class="fas fa-phone"></i>+57 3205128521</li>
                        <li><i class="fas fa-envelope-open-text"></i>centrocaninobetel@gmail.com</li>
                    </ul>
                    <p>Our team will contact you in a few days, thanks a lot for your message.</p>
                    <p>www.centrocaninobetel.com</p>
                </div>
            </div>
        </div>
    )
}

export default Form
