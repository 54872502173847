import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css'
import {LangProvider} from './context/langContext';
ReactDOM.render(
  <div>
  <LangProvider>
    <App />
  </LangProvider>
   
  </div>,
  document.getElementById('root')
);

