import React, {useState} from "react";
import MsgEnglish from'../lang/en-US.json';
import MsgSpanish from '../lang/es-CO.json';
import {IntlProvider} from 'react-intl';

const langContext = React.createContext();


 const LangProvider = ({children}) =>{

    const [msgs, setMsgs]= useState(MsgEnglish);
    const [locale, setLocale]= useState('en-US');
    const establecerLenguaje=(lenguaje)=>{
        console.log('cambiamos el lenguaje a:',lenguaje);
        switch(lenguaje){
            case 'es-CO':
                setMsgs(MsgSpanish);
                setLocale('es-CO');
                break;
            case 'en-US':
                setMsgs(MsgEnglish);
                setLocale('en-US');
                break; 
            default:
                setMsgs(MsgEnglish);
                setLocale('en-US');       
        }
    }
     return(
        <langContext.Provider value={{establecerLenguaje:establecerLenguaje}}>
            <IntlProvider  locale={locale} messages={msgs}>
            {children}
            </IntlProvider>
            
        </langContext.Provider>
     );
 } 
 
 export {LangProvider}
 export default langContext