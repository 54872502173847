import React from 'react'

function Subscribe() {
    
 
    return (
        <div id='subscribe'>
            <h3>Subscribe</h3>
            <div className='subscribe-input'>
                <input type="email" placeholder='example@gmail.com'required/>
                <a href-no-hash="#"><button type="submit">Continue</button></a>
            </div>
        </div>
    )
}

export default Subscribe
