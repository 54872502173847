import React from 'react'
import Boxcia from '../components/boxcia'
import Dog1 from '../images/canino1.PNG'
import Dog2 from '../images/canino7.PNG'
import Dog3 from '../images/canino3.PNG'
import {FormattedMessage} from 'react-intl'
function Company() {
    return (
        <div id='company'>
            <div className='b-container' >
                <Boxcia img={Dog1} title={<FormattedMessage id='company.tvision' defaultMessage='Vision'/>} string={<FormattedMessage id='company.cvision' defaultMessage='Our vision is to be recognized as the best option in the area of security through specialized canines, trained to provide services of company, defense and olfactory detection of substances, to individuals, families and companies; in a framework of protection and well-being of the canine and its environment.'/>} />
                <Boxcia img={Dog2}title={<FormattedMessage id='company.tmission' defaultMessage='Mission'/>} string={<FormattedMessage id='company.cmission' defaultMessage='Our Mission is to provide value to all our customers through the conditioning, preparation, training and supply of highly qualified canine specimens, prepared to meet the needs of companies and individuals.'/>} />
                <Boxcia img={Dog3}title={<FormattedMessage id='company.tvalues' defaultMessage='Values'/>} string={<FormattedMessage id='company.cvalues' defaultMessage='Responsibility: We fulfill our obligations to care for the canine and train it to protect, safeguard lives and property. Commitment: We use all our resources, experience and dedication to make our canines effective and effective. Authenticity: Expressed in truth as the basis of operation and method. Our canines respond to the expectation of our customers according to their specialty and trainings'/>}/>
            </div>
                
        </div>
    )
}

export default Company