import React from 'react'

function BoxCia(props) {
    return (
        <div className='c-box'>
            <h1 align='center'>{props.title}</h1>
            <div className='s-b-img'><img src={props.img} alt={props.alt}/></div>
            <div className='s-b-text'><p align='left'>{props.string}</p></div>
        </div>
    )
}

export default BoxCia
