import React from 'react';
import Reactplayer from 'react-player';
import '../video/responsive-player.css';

const Responsiveplayer = ({url})=>{
    
        return (
            <div className='player-wrapper'>
                <Reactplayer className='react-player' url={url} width='100%' height='100%' controls={true} />
            </div>
        )

}
export default Responsiveplayer