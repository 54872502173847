import React  from 'react';
import Navbar from './Navbar';

function Header() {
    return (
       
            <div id='main'>
                <Navbar />
                <div >
                    <div className='name'>
                
                    </div>
           
            
            
                    </div>
            
            </div>
        
    )
}

export default Header
