import React from 'react'
import './social.css'; 
import '@fortawesome/fontawesome-free'    
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faEnvelopeSquare,faShareAltSquare } from '@fortawesome/free-solid-svg-icons'
import{ faFacebookSquare,faInstagramSquare,faWhatsappSquare, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';

function Social() {
    return (
        <div className='social'>
            
            <ul>
                <li>
                    <a href='https://web.facebook.com/Centro-Canino-BETEL-105503254996983' target='_blank' rel='noopener noreferrer'>
                        <FontAwesomeIcon icon={faFacebookSquare} color="GreenYellow"/></a></li>
                <li>
                    <a href='https://www.instagram.com/centrocaninobetel/' target='_blank'rel='noopener noreferrer'>
                        <FontAwesomeIcon icon={faInstagramSquare} color="GreenYellow" /></a></li>
                <li>
                    <a href='https://www.youtube.com/channel/UCH9lCksX57QecS4NCqglYhw' target='_blank'rel='noopener noreferrer'>
                        <FontAwesomeIcon icon={faYoutubeSquare} color="GreenYellow"/></a></li>
                <li>
                    <a href='http://wa.me/573205128521' target='_blank'rel='noopener noreferrer'>
                        <FontAwesomeIcon icon={faWhatsappSquare} color="GreenYellow"/></a></li>
                <li>
                    <a href='mailto:centrocaninobetel@gmail.com?subject=Email%20Subject&Email%20body%20text'>
                        <FontAwesomeIcon icon={faEnvelopeSquare} color="GreenYellow"/></a></li>
                <li><a href="https://api.whatsapp.com/send?text=https://centrocaninobetel.com"><FontAwesomeIcon icon={faShareAltSquare} color="GreenYellow"/></a></li>
            </ul>
        </div>
    )
}
    
export default Social