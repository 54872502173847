import React from 'react'
import Responsiveplayer from '../video/responsiveplayer';
function videobox(props) {
    return (
        <div className='v-box'>
            <h2 align='center'>{props.title}</h2>
            <div className='s-b-img'>
            <Responsiveplayer url={props.url} />
            </div>
           
            
            <div className='s-b-text'>
                {props.features}
            </div>
        
        </div>
        
    )
}

export default videobox
